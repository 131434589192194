import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeleteBlockMutation, useGetBlocksQuery } from '../../../redux/apis/blocksApi';
import { useGetLangsQuery } from '../../../redux/apis/langApi';
import { useGetMediaQuery } from '../../../redux/apis/mediaApi';
import { pagesApi, useDeletePageMutation, useGetPagesQuery } from '../../../redux/apis/pagesApi';
import { sectionApi, useDeleteSectionMutation, useGetSectionsQuery } from '../../../redux/apis/sectionApi';
import { useGetTermsQuery } from '../../../redux/apis/termsApi';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/edit_icon.svg';
// import { ReactComponent as SettingsIcon2 } from '../../../assets/icons/settings_icon2.svg';
import settingsIcon2 from '../../../assets/icons/settings_icon2.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete_icon.svg';
// import { ReactComponent as DeleteIcon2 } from '../../../assets/icons/delete_icon2.svg';
import deleteIcon2 from '../../../assets/icons/delete_icon2.svg';
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu_icon.svg';
import { SectionModal } from './sectionModal/sectionModal';
import { BlocksModal } from './blocksModal/blocksModal';
import { key } from '../../../utils/getKey';
import { Arrows } from '../../../components/arrows/arrows';
import { Preloader } from '../../../components/preloader/preloader';
import { PagesModal } from './pagesModal/pagesModal';
import s from './pages.module.scss';
import Swal from 'sweetalert2';
import { Toast } from '../../../utils/hocs/toast'
import { MenuMini } from '../../../components/menuMini/menuMini';

export const Pages = ()=>{

    
    let sections_view = [];
    const dispatch = useDispatch();
    const [pagesModal, setPagesModal] = useState({state: false, id: false});
    const [sectionModal, setSectionModal] = useState({state: false, id: false});
    const [blocksModal, setBlocksModal] = useState({state: false, section: false, block: false});
    const [pageState, setPageState] = useState(false);
    const [langState, setLangState] = useState(false);

    const [deleteSection] = useDeleteSectionMutation();
    const [deleteBlock] = useDeleteBlockMutation();
    const [deletePage] = useDeletePageMutation();
 
    const sections = useGetSectionsQuery({});
    const blocks = useGetBlocksQuery();
    const terms = useGetTermsQuery({});
    const lang = useGetLangsQuery({});
    const media = useGetMediaQuery({});
    const pages = useGetPagesQuery({});

    const langs = useSelector(store => store.main.langs)
    const pagesMenusRefs = useRef([])
    
    
    if(sections.isError || blocks.isError || terms.isError) console.log("error");
    if(sections.isFetching || blocks.isFetching || terms.isFetching || lang.isFetching || media.isFetching || pages.isFetching) return <Preloader />

    if(sections.data && blocks.data && terms.data && lang.data && media.data && pages.data){
        
        if(!pages.data.data.length) return "";
        if(!pageState){
            if(pages.data){
                setPageState(pages.data.data[0]);
            }
            return;
        }
        if(!langState){
            setLangState(lang.data.data[0].id);
            return;
        }
       
        const page = pages.data.data.find(el => el.id === pageState.id);
        
        const section_data = page.content?.map(section => sections.data.data.find(el => el.id === section));

        sections_view = section_data?.map(el => {
            if(!el) return;
            const blocksInSection =  blocks.data.data?.filter(element => el.content.includes(element.id));
        
            return (
                <div key={el.id} className={s.section}>
                    <div className={s.header}>
                        <div className={s.info_block}>
                            {el.marker? <div className={s.header_item}><span className={s.title}>{`Marker: `}</span><span className={s.value}>{el.marker}</span></div> : null}
                        </div>
                        <p>{`Секція `}<span>{`${el.name}`}</span></p>
                        <div className={s.tools}>
                            <span className={s.edit_icon} onClick={()=>toggleSectionModal(el)}>{<SettingsIcon/>}</span>
                            <span className={s.delete_icon} onClick={()=>delSection(el)}><DeleteIcon/></span>
                        </div>
                        <span className={s.edit_icon}></span>
                    </div>
                    {   
                        blocksInSection.map(block => {
                            if(block.type === "text" || block.type === "link"){
                                return(
                                    <div key={block.id} className={`${s.block} anchor-${block.id}`}>
                                        <header>
                                            <div className={s.info_block}>
                                                <div className={s.header_item}><span className={s.title}>{`Type: `}</span><span className={s.value}>{block.type}</span></div>
                                                <div className={s.header_item}><span className={s.title}>{`ID: `}</span><span className={s.value}>{block.id}</span></div>
                                                {block.text? <div className={s.header_item}><span className={s.title}>{`Pseudo: `}</span><span className={s.value}>{block.text}</span></div>:null}
                                                {block.link? <div className={s.header_item}><span className={s.title}>{`Link: `}</span><span className={s.value}>{block.link}</span></div>:null}
                                                {block.marker? <div className={s.header_item}><span className={s.title}>{`Marker: `}</span><span className={s.value}>{block.marker}</span></div> : null}
                                            </div>
                                            <div className={s.tools}>
                                                <span className={s.edit_icon} onClick={()=>toggleBlockModal(el ,block)}>{<SettingsIcon/>}</span>
                                                <span className={s.delete_icon} onClick={()=>delBlock(block)}><DeleteIcon/></span>
                                            </div>
                                        </header>
                                        <div className={s.content}>
                                            <div className={s.lang_block}>
                                                {
                                                lang.data.data.map(el => {
                                                    return (
                                                        <div key={key()} className={s.lang_item} onClick={()=>setLangState(el.id)}>
                                                            <div className={s.lang}>{el.key}</div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                            <div className={s.text_block}>
                                                <p>{terms?.data?.data?.find(tm => tm.pseudo === block.text && tm.lang_id === langState)?.value}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            else if(block.type === 'image' || block.type === 'video'){
                                const files = media.data.data.filter(el => {return el.pseudo === block.file? el.path : false});
                                
                                return(
                                    <div key={block.id} className={`${s.block} anchor-${files?.[0]?.id}`}>
                                        <header>
                                            <div className={s.info_block}>
                                                <div className={s.header_item}><span className={s.title}>{`Type: `}</span><span className={s.value}>{block.type}</span></div>
                                                <div className={s.header_item}><span className={s.title}>{`ID: `}</span><span className={s.value}>{files?.[0]?.id}</span></div>
                                                <div className={s.header_item}><span className={s.title}>{`Group: `}</span><span className={s.value}>{files?.[0]?.group}</span></div>
                                                {block.type === 'image'? <div className={s.header_item}><span className={s.title}>{`alt: `}</span><span className={s.value}>{files?.[0]?.alt}</span></div>:null}
                                                {block.marker? <div className={s.header_item}><span className={s.title}>{`Marker: `}</span><span className={s.value}>{block.marker}</span></div> : null}
                                            </div>
                                            <div className={s.tools}>
                                                <span className={s.edit_icon} onClick={()=>toggleBlockModal(el, block, files)}>{<SettingsIcon/>}</span>
                                                <span className={s.delete_icon} onClick={()=>delBlock(block)}><DeleteIcon/></span>
                                            </div>
                                        </header>
                                        <div className={s.content}>
                                        <div className={s.images_content}>
                                            {langs?.filter(ln => files.find(el => el.langkey === ln.key)?.link)?.map((ln, index) => (
                                                    <div className={s.image_item_wrapper} key={index}>
                                                        <div className={`${s.header} ${files.find(el => el.langkey === ln.key)?.default_image? s.color : ""}`}>
                                                            <span className={s.language}>{ln.key}</span>
                                                            {
                                                                files.find(el => el.langkey === ln.key)?.default_image && <span>| Default</span>
                                                            }
                                                        </div>
                                                        {
                                                            block.type === 'image'? 
                                                                <div className={s.image_wrapper} style={{
                                                                    backgroundImage: `url(${files.find(el => el.langkey === ln.key)?.link})`
                                                                }}></div>
                                                            :
                                                            <div className={s.image_wrapper}>
                                                                <video playsInline src={files.find(el => el.langkey === ln.key)?.link} controls={true} style={{width: '100%', height: '100%'}}/>
                                                            </div>
                                                        }
                                                    </div>
                                            ))}
                                        </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                    <span className='button' onClick={()=> toggleBlockModal(el)}>Додати блок</span>
                   
                </div>
            )
        })
    }


    function toggleBlockModal(el, block = false, file = undefined){
        let terms_block = [];
        terms.data.data.forEach(el => {if(el.pseudo === block.text) terms_block[el.lang_id] = el});
        const values = {
            'type': block.type,
            'link': block.link? block.link : false,
            'pseudo': block.text || file?.[0]?.pseudo,
            'block_id': block.id,
            'file': file,
            'terms_block': terms_block,
            'marker': block.marker
        }
        setBlocksModal((prev)=> {return {state: !prev.state, section: el, values: values, lang_data: lang.data.data}})
    }

    function toggleSectionModal(el = false){
        setSectionModal({state: true, el: el})
    }

    async function delSection(el){
        Swal.fire({
            title: 'Видалити секцію?',
            text: `Ви впевнені, що хочете видалити секцію ${el.name}? Разом з нею буде видалено весь її контент!`,
            showCancelButton: true,
            cancelButtonText: 'Скасувати',
            confirmButtonText: 'Видалити',
            icon: 'question',
            customClass: {
              actions: 'modal',
              cancelButton: 'order-1 right-gap',
              confirmButton: 'order-2',
              denyButton: 'order-3',
            }
          }).then(async (result) => {
            if(result.isConfirmed){
                
                const result = await deleteSection({section: el.id, page_id: pageState.id});
                if(result.error?.data.message === 1000){
                    Swal.fire({
                        icon: 'error',
                        title: 'На жаль, у вас немає необхідних прав',
                        confirmButtonText: 'Закрити',
                        customClass: {
                            actions: 'modal',
                            cancelButton: 'order-1 right-gap',
                            confirmButton: 'order-2',
                            denyButton: 'order-3',
                        }
                    })
                }
                else if (result.data.message === 37){
                    dispatch(sectionApi.util.resetApiState(['Pages']))
                    dispatch(sectionApi.util.resetApiState(['Section']));
                    Toast.fire({icon: 'success', title: 'Успіх'});
                }
                
            }
        })
    }

    async function delBlock(block){
        Swal.fire({
            title: `Видалити блок ${block.type}?`,
            showCancelButton: true,
            cancelButtonText: 'Скасувати',
            confirmButtonText: 'Видалити',
            icon: 'question',
            customClass: {
              actions: 'modal',
              cancelButton: 'order-1 right-gap',
              confirmButton: 'order-2',
              denyButton: 'order-3',
            }
          }).then(async (result) => {
            if(result.isConfirmed){
                const res = await deleteBlock([block.id]);
                if(res.error?.data.message === 1000){
                    Swal.fire({
                        icon: 'error',
                        title: 'На жаль, у вас немає необхідних прав',
                        confirmButtonText: 'Закрити',
                        customClass: {
                          actions: 'modal',
                          cancelButton: 'order-1 right-gap',
                          confirmButton: 'order-2',
                          denyButton: 'order-3',
                        }
                    })
                }
                else if(res.data.message === 40){
                    Toast.fire({icon: 'success', title: 'Успіх'});
                    dispatch(sectionApi.util.invalidateTags(['Section']));
                }
            }
        })
    }

    async function delPage(page){
        const data = page? page : pageState
        Swal.fire({
            title: 'Видалити сторінку?',
            text: `Ви впевнені, що хочете видалити сторінку ${data.name}? Разом з нею буде видалено весь її контент!`,
            showCancelButton: true,
            cancelButtonText: 'Скасувати',
            confirmButtonText: 'Видалити',
            icon: 'warning',
            customClass: {
              actions: 'modal',
              cancelButton: 'order-1 right-gap',
              confirmButton: 'order-2',
              denyButton: 'order-3',
            }
          }).then(async (result) => {
            if(result.isConfirmed){
                const result = await deletePage(data.id);
                if(result.error?.data.message === 1000){
                    Swal.fire({
                        icon: 'error',
                        title: 'На жаль, у вас немає необхідних прав',
                        confirmButtonText: 'Закрити',
                        customClass: {
                          actions: 'modal',
                          cancelButton: 'order-1 right-gap',
                          confirmButton: 'order-2',
                          denyButton: 'order-3',
                        }
                    })
                }
                else if(result.data.message === 33){
                    Toast.fire({icon: 'success', title: 'Успіх'});
                    dispatch(pagesApi.util.resetApiState(['Pages']));
                    setPageState(false);
                }
            }
        })
    }
    
    const pages_view = pages.data.data.map(el => {
        const menu_items = [
            {icon: settingsIcon2, text: "Редагувати", callback: () => setPagesModal({state: true, el: el})},
            {icon: deleteIcon2, text: "Видалити", callback: () => delPage(el)},
        ]
        return(
            <div key={el.id} className={`${s.page_item} ${pageState.id === el.id? s.active:''}`}>
                <p onClick={()=> setPageState(pages.data.data.find(page => page.id === el.id))}>{el.name}</p>
                <span className={s.icon_wrapper} onClick={()=>pagesMenusRefs[el.name].style.cssText = 'opacity: 1; visibility: visible;'}><MenuIcon/></span>
                <MenuMini items={menu_items} refLink={pagesMenusRefs} name={el.name}/>
            </div>
        )
    })


    return(
        <div className={s.pages}>
            <header>
                {pages_view}
                <div className={s.add_page} onClick={()=>setPagesModal({state: true, el: false})}>&#10010;</div>
            </header>
            <div className={s.content}>
                <div className={s.left}>
                    {sections_view}
                    <div className={s.section}>
                        <div className={s.tools}>
                            <span className='button' onClick={()=> toggleSectionModal()}>Додати нову секцію</span>
                        </div>
                    </div>
                </div>
            </div>
            <Arrows/>
            {pagesModal.state? <PagesModal close={setPagesModal} el={pagesModal.el} pageState = {pageState}/> : null }
            {sectionModal.state? <SectionModal close={setSectionModal} el={sectionModal.el} pageState = {pageState}/> : null }
            {blocksModal.state? <BlocksModal close={setBlocksModal} section={blocksModal.section} values={blocksModal.values} lang_data={blocksModal.lang_data}/> : null }
        </div>
    )
}