import React from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { useSaveDatabaseMutation } from '../../redux/apis/userApi';
import { Language } from './language/language';
import s from './main.module.scss';
import { Pages } from './pages/pages';
import { Terms } from './terms/terms';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings_icon.svg';
import { Settings } from './settings/settings';
import { Users } from './users/users';
import { Toast } from '../../utils/hocs/toast';
import { confirm } from '../../utils/hocs/confirm';
import { Permissions } from '../../utils/hocs/permissions';
import Swal from 'sweetalert2';
import { useGetModulesQuery } from '../../redux/apis/modulesApi';
import { Module } from './module/module';
import { AddPost } from './addPost/addPost';
import { Instagram } from './instagram/instagram';
import { useGetLangsQuery } from '../../redux/apis/langApi';
import { Preloader } from '../../components/preloader/preloader';

export const Main = ()=>{

    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const userData = JSON.parse(localStorage.getItem('userData'));

    const [saveDatabase] = useSaveDatabaseMutation();
    const modules = useGetModulesQuery();
    const {isLoading} = useGetLangsQuery({});
    
    async function logOut(){
        confirm("Вийти з акаунту?").then((res)=>{
            if(res.isConfirmed){
                localStorage.removeItem('token');
                localStorage.removeItem('userData');
                navigate("/auth/");
            }
        })
    }
   
    const modules_view = modules.data?.map(el => {
        return (
            <div key={el.id} className={s.menu_item}>
                <SettingsIcon/>
                <Link to={`module/${el.type}`}>{el.title}</Link>
            </div>
        )
    })

    const routes_view = modules.data?.map(el => {
        return (
            <Route key={el.id} path={`/module/${el.type}`} element={<Permissions page="modules" type="view"><Module id={el.id} title={el.title}/></Permissions>}/>
        )
    })

    if(!token) return <h1>Loading...</h1>;
    else return(
        <div className={s.main}>

<div id="fb-root"></div>

            <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/ru_RU/sdk.js#xfbml=1&version=v18.0&appId=113943141723175&autoLogAppEvents=1" nonce="4aOlElo0"></script>


            <section className={s.left_menu}>
                <div className={s.menu}>
                    <div className={s.menu_item}>
                        <SettingsIcon/>
                        <Link to="/">Сторінки сайту</Link>
                    </div>
                    <div className={s.menu_item}>
                        <SettingsIcon/>
                        <Link to="/language/">Мови</Link>
                    </div>
                    <div className={s.menu_item}>
                        <SettingsIcon/>
                        <Link to="/terms/">Терміни</Link>
                    </div>
                    {userData.id > 1? null : (
                        <div className={s.menu_item}>
                            <SettingsIcon/>
                            <Link to="users/">Юзери</Link>
                        </div>
                    )}
                    {/* <div className={s.menu_item}>
                        <SettingsIcon/>
                        <Link to="/instagram/">Instagram</Link>
                    </div> */}
                    {modules_view}
                </div>
                <div className={s.bottom_menu}>
                    <div className={s.user_menu}>
                        <p>Привіт, <span>{userData.info.name}</span></p>
                        <p>{userData.email}</p>
                    </div>
                    <div className={s.menu_item}>
                        <p onClick={logOut}>Вийти</p>
                    </div>
                </div>
            </section>
            <section className={s.content}>
                {
                    isLoading ? <Preloader/> :
                    <Routes>
                        <Route path="*" element={<Permissions page="pages" type="view"><Pages/></Permissions>}/>
                        <Route path="/addpost/*" element={<Permissions page="posts" type="my"><AddPost/></Permissions>}/>
                        <Route path="/users/" element={<Users/>}/>
                        <Route path="/terms/" element={<Permissions page="terms" type="view"><Terms/></Permissions>}/>
                        <Route path="/language/" element={<Permissions page="langs" type="view"><Language/></Permissions>}/>
                        <Route path="/instagram/" element={<Instagram/>}/>
                        <Route path="/settings/" element={<Settings/>}/>
                        {routes_view}
                    </Routes>
                }
            </section>
            
        </div>
    )
}