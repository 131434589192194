import React, { useEffect, useRef, useState } from 'react';
import { blocksApi, useAddBlockNewMutation, useGetBlocksTypesQuery, useUpdateBlockNewMutation } from '../../../../redux/apis/blocksApi';
import { useGetLangsQuery } from '../../../../redux/apis/langApi';
import { termsApi, useCheckTermsPseudoMutation, useGetTermsQuery } from '../../../../redux/apis/termsApi';
import { sectionApi } from '../../../../redux/apis/sectionApi';
import { mediaApi, useGetMediaQuery } from '../../../../redux/apis/mediaApi';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from '../../../../utils/hocs/toast';
import Swal from 'sweetalert2';
import s from './blocksModal.module.scss'
import { Preloader } from '../../../../components/preloader/preloader';

export const BlocksModal = ({close, section, values, lang_data, type, accept, tm_name, post_body})=>{
    let types_view;
    let groups_view;
    let groups_view_media;
   
    const ln = useSelector(store => store.main.langs)
    const dispatch = useDispatch();
    const pseudo_ref = useRef();
    const marker_ref = useRef();
    const new_group_ref = useRef();
    const img_wrapper = useRef({});
    const group_select = useRef();
    const type_select = useRef();
    const error_text = useRef();
    const video = useRef({});
    const terms_values = useRef([]);
    const extra_values = useRef([]);

    const [formContent, setFormContent] = useState();
    const [content_type, setType] = useState('text');
    const [inputsValues, setInputsValues] = useState({
        ...values?.terms_block, 
        'link': {value: values?.link? values.link : ''}, 
        'pseudo': {value: values?.pseudo? values.pseudo : ''},
        'alt': {value: values?.file?.[0]?.alt? values?.file?.[0]?.alt : ''},
        'marker': {value: values?.marker? values.marker : ''}
    });
    const [defaultImage, setDefaultImage] = useState(undefined)
    const [activeImages, setActiveImages] = useState({})
    
    const [addBlockNew, {isLoading: isAddBlockLoading}] = useAddBlockNewMutation();
    const [updateBlockNew, {isLoading: isUpdateBlockLoading}] = useUpdateBlockNewMutation();
    const [checkTerms, {isLoading: isCHeckTermsLoading}] = useCheckTermsPseudoMutation();
    
    const {data, isError, isFetching} = useGetBlocksTypesQuery();
    const terms = useGetTermsQuery({});
    const lang = useGetLangsQuery({});
    const media = useGetMediaQuery({});

    useEffect(()=>{
        values?.file?.forEach(file => {
            if(file.default_image) setDefaultImage({langkey: file.langkey, id: undefined})
        })
    },[]);

    useEffect(()=>{
        values?.file?.forEach(el => {
            if(el.link)
            setActiveImages(prev => {return{ ...prev, [el.langkey]: true }})
        })
    },[values.file]);

    if(isFetching || terms.isFetching || lang.isFetching || media.isFetching || isAddBlockLoading || isUpdateBlockLoading || isCHeckTermsLoading) return <Preloader/>
    if(isError) console.log(isError);

    // ### SET CONTENT TYPE

    if(!formContent){
        if(values?.type) changeType(values?.type);
        else if(type) changeType(type);
        else changeType("text");
        return;
    }
    

    // ### VIEWS

    const uniqueArray = terms.data.data.filter((obj, index, self) => {
        return self.findIndex((otherObj) => obj.group === otherObj.group) === index;
        });
    
    groups_view = uniqueArray.map(el => {
        return(
            <option key={el.id} name={el.group} value={el.group}>{el.group}</option> 
        )
    })
    
    const uniqueArrayMedia = media.data.data.filter((obj, index, self) => {
        return self.findIndex((otherObj) => obj.group === otherObj.group) === index;
        });
    groups_view_media = uniqueArrayMedia.map(el => {
        return(
            <option key={el.id} value={el.group}>{el.group}</option> 
        )
    })
    
    types_view = data.data.map(el => {
        return (
            <option key={el.id} value={el.name}>{el.name}</option>
        )
    })
    

    // ### FUNCTIONS

    function changeType(value){
        switch(value){
            case "text":
            setFormContent(data?.data.find(el => el.name === value).options[0])
            setType('text')
            break;
            
            case "link":
            setFormContent(data?.data.find(el => el.name === value).options[0])
            setType('link')
            break;

            case "button":
            setFormContent(data?.data.find(el => el.name === value).options[0])
            setType('button')
            break;

            case "image":
            setFormContent(data?.data.find(el => el.name === value).options[0])
            setType('image')
            break;

            case "video":
            setFormContent(data?.data.find(el => el.name === value).options[0])
            setType('video')
            break;

            default: Toast.fire({icon: 'success', title: `Не вірний тип.`});
        }
    }

    async function sendForm(ev){
        let err_flag = false;
        error_text.current.innerHTML = "";
        ev.preventDefault();
        
        if(formContent[0].type === 'text' || formContent[0].type === 'link'){
            const pseudo = inputsValues['pseudo'].value;
            const marker = inputsValues['marker'].value;
            const old_group = group_select.current.value;
            const new_group = new_group_ref.current.value;
            const group = new_group.length? new_group : old_group;
            const link = extra_values?.current['link']?.value? extra_values?.current['link']?.value : '';
            
            if(pseudo.length < 2) {
                error_text.current.innerHTML = "Заповніть pseudo";
                err_flag = true;
            }
            if(marker.length < 2) {
                error_text.current.innerHTML = "Заповніть marker";
                err_flag = true;
            }
            lang?.data.data.forEach(el=>{
                if(terms_values.current[el.key].value.length < 1) {
                    error_text.current.innerHTML = "Заповніть усі поля";
                    err_flag = true;
                }
            });

            if(err_flag) return;
            
            if(accept){
                const result_terms = {}
                result_terms.text = lang.data.data.map((el) => {
                    return {value: terms_values.current[el.key].value, pseudo: pseudo, group: group, lang_id: el.id}
                });
                if(tm_name) result_terms.tm_name = tm_name;
                if(post_body) result_terms.post_body = "post_body";
                accept(result_terms);
                close(false);
                return;
            }

            let terms = [];
            lang?.data.data.map( async el => {
                terms.push({value: terms_values.current[el.key].value, pseudo: pseudo, group: group, lang_id: el.id})
            });
            
            // Edit block
            if(values.type){
                const response = await updateBlockNew({type: content_type, section_id: section.id, link: link, block_id: values.block_id, terms: terms, marker: inputsValues.marker.value});
                if(response.error?.data.message === 1000){
                    Swal.fire({
                        icon: 'error',
                        title: 'На жаль, у вас немає необхідних прав',
                        confirmButtonText: 'Закрити',
                        customClass: {
                          actions: 'modal',
                          cancelButton: 'order-1 right-gap',
                          confirmButton: 'order-2',
                          denyButton: 'order-3',
                        }
                    })
                    close(false);
                }
                else if(response.data.message === 38){
                    dispatch(termsApi.util.resetApiState())
                    dispatch(blocksApi.util.resetApiState())
                    dispatch(sectionApi.util.resetApiState())
                    Toast.fire({icon: 'success', title: 'Успіх'});
                    close(false)
                }
                return;
            }
            
            const check_terms = await checkTerms([pseudo]);
            if(check_terms.data){
                Toast.fire({icon: 'success', title: `Псевдонім ${pseudo} вже зайнятий. Будь ласка, використовуйте унікальні pseudo для тексту.`});
                return;
            }
            
            // Add new block
            const response = await addBlockNew({type: content_type, section_id: section.id, link: link, terms: terms, marker: inputsValues.marker.value});
            if(response.error?.data.message === 1000){
                Swal.fire({
                    icon: 'error',
                    title: 'На жаль, у вас немає необхідних прав',
                    confirmButtonText: 'Закрити',
                    customClass: {
                      actions: 'modal',
                      cancelButton: 'order-1 right-gap',
                      confirmButton: 'order-2',
                      denyButton: 'order-3',
                    }
                })
                close(false);
            }
            else if(response.data.message === 38){
                dispatch(termsApi.util.resetApiState())
                dispatch(blocksApi.util.resetApiState())
                dispatch(sectionApi.util.resetApiState())
                Toast.fire({icon: 'success', title: 'Успіх'});
                close(false);
                return;
            }
                
        }
        else if(formContent[0].type === 'file'){
            
            let err_flag = false;
            const old_group = group_select.current.value;
            const new_group = new_group_ref.current.value;
            const group = new_group.length? new_group : old_group;
            const alt = extra_values.current['alt']?.value;
            const block_id = values?.block_id;

            const form_data = new FormData();
            extra_values?.current.forEach(file => form_data.append(`file-${file.langkey}`, file.file))

            form_data.append("group", group);
            form_data.append("alt", alt);
            form_data.append("media_id", values?.pseudo);
            form_data.append("block_id", block_id);
            form_data.append("file_type", content_type);
            form_data.append("section_id", section?.id);
            form_data.append("marker", inputsValues.marker.value);
            form_data.append("default_image", defaultImage.langkey);
            console.log("form_data", form_data);

            if(content_type === 'image'){
                if(group.length < 2 || alt.length < 2) {
                    error_text.current.innerHTML = "Заповніть усі поля";
                    err_flag = true;
                }
            }
            if(content_type === 'video'){
                if(group.length < 2) {
                    error_text.current.innerHTML = "Заповніть усі поля";
                    err_flag = true;
                }
            }

            if(err_flag) return;
            if(accept){
                const result_image = {}
                result_image.image = form_data;
                if(tm_name) result_image.tm_name = tm_name;
                if(post_body) result_image.post_body = "post_body";
                accept(result_image);
                close(false);
                return;
            }

            // Edit block
            if(values.type){
                const response = await updateBlockNew(form_data);
                if(response.error?.data.message === 1000){
                    Swal.fire({
                        icon: 'error',
                        title: 'На жаль, у вас немає необхідних прав',
                        confirmButtonText: 'Закрити',
                        customClass: {
                          actions: 'modal',
                          cancelButton: 'order-1 right-gap',
                          confirmButton: 'order-2',
                          denyButton: 'order-3',
                        }
                    })
                    close(false);
                }
                else if(response.data.data.message === 38){
                    dispatch(mediaApi.util.resetApiState())
                    dispatch(blocksApi.util.resetApiState())
                    dispatch(sectionApi.util.resetApiState())
                    Toast.fire({icon: 'success', title: 'Успіх'});
                    close(false)
                }
                return;
            }
            
            // New block
            const response = await addBlockNew(form_data);
            if(response.data.message === 38){
                dispatch(mediaApi.util.resetApiState())
                dispatch(blocksApi.util.resetApiState())
                dispatch(sectionApi.util.resetApiState())
                Toast.fire({icon: 'success', title: 'Успіх'});
                close(false);
                return;
            }
            
        }
        
    }

    function previewImage(input, lnkey){
        if(input.name === "image"){
            const reader = new FileReader();
            reader.onload = function(e) {
                img_wrapper.current[lnkey].style.cssText= `background: url(${e.target.result}) no-repeat center; background-size: contain;`;
                setActiveImages(prev => {return{ ...prev, [lnkey]: true }})
                extra_values.current = extra_values.current.filter(file => file.langkey !== lnkey)
                extra_values.current.push({
                    langkey: lnkey,
                    file: input.files[0]
                })
                setActiveImages(prev => {return{ ...prev, [lnkey]: true }})
                if(!defaultImage) setDefaultImage({langkey: lnkey, id: undefined})
            };
            reader.readAsDataURL(input.files[0]);
        }

        if(input.name === "video"){
            const reader = new FileReader();
            reader.onload = function(e) {
                video.current[lnkey].src = e.target.result;
                video.current[lnkey].setAttribute('controls', true);
                extra_values.current = extra_values.current.filter(file => file.langkey !== lnkey)
                extra_values.current.push({
                    langkey: lnkey,
                    file: input.files[0]
                })
                setActiveImages(prev => {return{ ...prev, [lnkey]: true }})
                if(!defaultImage) setDefaultImage({langkey: lnkey, id: undefined})
            };
            reader.readAsDataURL(input.files[0]);
        }
    }

    function changeGroup(ev){
        if(ev.target.value.length){
            group_select.current.disabled = true;
        }else group_select.current.disabled = false;
    }

    function changeInput(el){

        setInputsValues(prev => {
            const newState = JSON.parse(JSON.stringify(prev));
            if(newState[el.getAttribute('refval')]) newState[el.getAttribute('refval')].value = el.value;
            return newState;
        });
    }

    function CopyText(){
        const currentValue = terms_values.current.eng.value
        lang?.data?.data?.map(el => {
            terms_values.current[el.key].value = currentValue
        })
    }
    console.log("formContent", formContent);
    return(
        <div className="modal_wrapper">
            <div className={`modal ${content_type === 'image' || content_type === 'video'? s.modal : ''}`}>
                <h2>{values?.type? "Змінити Блок" : "Додати Блок"}</h2>
                <form className="modal_form" >
                    <div className="form_row">
                        <label>Тип</label>
                        <select value={content_type} disabled={values?.type || type? true:false}  type="select" onChange={(ev)=>changeType(ev.target.value)} ref={type_select}>
                            {types_view}
                        </select>
                    </div>
                    {formContent?.[0]?.label === 'Текст' ? <span className={s.copy} onClick={CopyText}>Copy</span> : null}
                    {
                    formContent.map((el, index) => {
                        if(el.label === "Текст"){
                            return(
                                lang?.data?.data.map((ln)=>{
                                    
                                    return(
                                        <div key={ln.id} className="form_row">
                                            <label>{`${el.label}-${ln.key}`}</label>
                                            <textarea type={el.type} name={`${el.label}-${ln.key}`} refval={ln.id} value={inputsValues[ln.id]?.value} ref={ref=>terms_values.current[ln.key] = ref} onChange={(ev)=>changeInput(ev.target)} rows="10"></textarea>
                                        </div>
                                    )
                                })
                            )     
                        }
                        else if(el.label === "Ссылка"){
                            return(
                                <div key={ln.id} className="form_row">
                                    <label>{el.label}</label>
                                    <input type={el.type} value={inputsValues['link'].value} name={el.name} onChange={(ev)=>changeInput(ev.target)} ref={ref=>extra_values.current[el.name] = ref} refval={'link'}/>
                                </div>
                            )
                        }
                        else{
                            return(
                                <div key={index} className="form_row">
                                    <label>{el.label}</label>
                                    {el.name === 'alt'? 
                                        <input type={el.type}  name={el.name} value={inputsValues['alt'].value} onChange={(ev)=> changeInput(ev.target)} ref={ref=>extra_values.current[el.name] = ref} refval={'alt'}/>
                                        :
                                        el.name === 'image' ?
                                        <div className={s.image_items_wrapper}>
                                            {ln?.map((ln, index) => (
                                                    <div className={s.image_item_wrapper} key={index}>
                                                        <div className={s.header}>
                                                            <span className={s.lang}>{ln.key} |</span>
                                                            <div className={s.default_wrapper}>
                                                            <input type="checkbox" checked={defaultImage?.langkey === ln.key} onChange={()=>setDefaultImage({langkey: ln.key, id: values.file?.find(el=>el.langkey === ln.key)?.id})} disabled={!activeImages[ln.key]}/>
                                                                <span>default</span>
                                                            </div>
                                                        </div>
                                                        <div className={s.image_wrapper} ref={(el) => img_wrapper.current[ln.key] = el} style={{backgroundImage: `url(${values.file?.find(el=>el.langkey === ln.key)?.link})`}}>
                                                            <input className={s.change_input} name='image' type="file" accept='image/*' onChange={ev => previewImage(ev.target, ln.key, values.file?.find(el=>el.langkey === ln.key)?.id)}/>
                                                        </div>
                                                    </div>
                                            ))}
                                        </div>
                                        :
                                        <div className={s.image_items_wrapper}>
                                            {ln?.map((ln, index) => (
                                                    <div className={s.image_item_wrapper} key={index}>
                                                        <div className={s.header}>
                                                            <span className={s.lang}>{ln.key} |</span>
                                                            <div className={s.default_wrapper}>
                                                            <input type="checkbox" checked={defaultImage?.langkey === ln.key} onChange={()=>setDefaultImage({langkey: ln.key, id: values.file?.find(el=>el.langkey === ln.key)?.id})} disabled={!activeImages[ln.key]}/>
                                                                <span>default</span>
                                                            </div>
                                                        </div>
                                                        <div className={s.videos_wrapper} ref={(el) => img_wrapper.current[ln.key] = el} style={{backgroundImage: `url(${values.file?.find(el=>el.langkey === ln.key)?.link})`}}>
                                                            <input className={s.change_input} name='video' type="file" accept='video/*' onChange={ev => previewImage(ev.target, ln.key, values.file?.find(el=>el.langkey === ln.key)?.id)}/>
                                                            <div className={s.video_wrapper}>
                                                                <video playsInline ref={(el) => video.current[ln.key] = el} src={values.file?.find(el=>el.langkey === ln.key)?.link} controls={values.file?.path? true:false} style={{width: "100%", height: "100%"}} />
                                                            </div>
                                                        </div>
                                                    </div>
                                            ))}
                                        </div>
                                       
                                    }
                                </div>
                            )
                        }
                    })
                    }
                   
                        
                    <div className='form_group'>
                        <div className="form_row_group">
                            <div className="left">
                                <label>Вибрати групу</label>
                                <select ref={group_select} name="group" defaultValue={values?.type? values.type === 'text'? values.terms_block[values.terms_block.length - 1].group: values?.file?.group : false}>
                                    {content_type === 'image' || content_type === 'file'? groups_view_media : groups_view}
                                </select>
                            </div>
                            <div className="right">
                                <label>Додати нову</label>
                                <input type="text" ref={new_group_ref} onChange={(ev)=>{changeGroup(ev)}}/>
                            </div>
                        </div>
                        {content_type === "text" || content_type === "link"? (
                        <div className="form_row">
                            <label>Псевдонім</label>
                            <input onChange={(ev)=>changeInput(ev.target)} refval='pseudo' type="text" name="pseudo" value={inputsValues['pseudo'].value} ref={pseudo_ref} disabled={values?.type? true:false}/>
                        </div>
                        ) : null}
                        <div className="form_row">
                            <label>Marker</label>
                            <input onChange={(ev)=>changeInput(ev.target)} refval='marker' type="text" name="marker" value={inputsValues['marker'].value} ref={marker_ref} disabled={false}/>
                        </div>
                    </div>
                    
                    
                     <span className="error_text" ref={error_text}></span>
                    <input className="button" type="button" value="Відправити" onClick={(ev)=>sendForm(ev)} />
                    <input className="button" type="submit" value="Скасувати" onClick={()=>close(false)}/>
                </form>
            </div>
        </div>
    )
}