import React, { useRef, useState } from 'react';
import s from './addModuleItem.module.scss';
import { AddModuleBlock } from '../addModuleBlock/addModuleBlock';
import { useSelector } from 'react-redux';
import { findInputValue } from '../../../utils/FindInputValue';

export const AddModuleItem = ({ item, setModal, changeValuesData, setFiles, files }) => {
   
    const [activeImages, setActiveImages] = useState({})
    const [defaultImage, setDefaultImage] = useState(false)
    const ln = useSelector(store => store.main.langs)
    const img_wrapper = useRef({})
    const video = useRef()
    if(item.isBlock){
        return <AddModuleBlock new_post={item} post={item.values} setModal={setModal} inner={true} title={item.title} changeValuesData={changeValuesData} setFiles={setFiles} files={files}/>
    }

    function changeValue(new_value, editId, lang_id){
        changeValuesData(prev => {
            const newState = JSON.parse(JSON.stringify(prev));
            const updated_data = findInputValue(newState, editId, lang_id, new_value)
        
            return updated_data
        })
    }

    function changeDefaultImage(lnkey){
        const current_item = files.find(el => el.editID === item.editId)
        if(!current_item) return
        setDefaultImage(lnkey)
        setFiles(prev => {
            return prev.map(file => {
                if(file.editID === item.editId){
                    const newObject = {editID: file.editID, file: {}}
                    Object.entries(file.file)?.forEach(file => {
                        newObject['file'][file[0]] = {...file[1], default: file[0] === lnkey}
                    })
                    return newObject
                }
                else return file
            })
        })
    }

    function previewImage(input, editId, lnkey){
        if(input.name === "image"){
            const reader = new FileReader();
            reader.onload = function(e) {
            img_wrapper.current[lnkey].style.cssText= `background: url(${e.target.result}) no-repeat center; background-size: contain;`;
            };
            reader.readAsDataURL(input.files[0]);
            setFiles(prev => {
                const newFile = {
                    file:
                    {
                        ...prev.find(el => el.editID === editId)?.file,
                        [lnkey] : {
                            file: input.files[0],
                            default: !defaultImage? true : false
                        }
                    },
                    editID: editId,
                }
                prev = prev.filter(el => el.editID !== editId)
                return [...prev, newFile]
            })
            if(!defaultImage) setDefaultImage(lnkey)
            setActiveImages(prev => { return {...prev, [lnkey]: true} })
        }

        if(input.name === "video"){
            const reader = new FileReader();
            reader.onload = function(e) {
                video.current.src = e.target.result;
                video.current.setAttribute('controls', true);
            };
            reader.readAsDataURL(input.files[0]);
        }        
    }
    
    const text_view = ln.map((ln, index) => {
        return(
            <div key={index} className={s.text_view_wrapper}>
                <div className={s.title}>{`${item.title} - `}<span>{`${ln.key}`}</span></div>
                {item.type === "text"? 
                <textarea 
                    name="text" 
                    placeholder='Текст...' 
                    rows="6" 
                    value={item.values.find(el=> el.lang_id === ln.id).value}
                    onChange={ev=>changeValue(ev.target.value, item.editId, ln.id)}
                >
                </textarea> : null}
            </div>
        )
    })

    return(
        <div className={s.item}>
                <span className={s.item_title}>{item.title}</span>
                <div className={s.content}>
                    {item.type === "text" || item.type === "link"?
                    text_view :
                        <div className={s.image_items_wrapper}>
                            {ln?.map(ln => (
                                <div className={s.image_item_wrapper}>
                                    <div className={s.header}>
                                        <span className={s.lang}>{ln.key} |</span>
                                        <div className={s.default_wrapper}>
                                        <input type="checkbox" checked={defaultImage === ln.key} onChange={()=>changeDefaultImage(ln.key)} disabled={!activeImages[ln.key]}/>
                                            <span>default</span>
                                        </div>
                                    </div>
                                    <div className={s.image_wrapper} ref={(el) => img_wrapper.current[ln.key] = el} >
                                        <input className={s.change_input} name={item.type} type="file" accept={item.type === 'image'? 'image/*' : 'video/*'} onChange={ev => previewImage(ev.target, item.editId, ln.key)}/>
                                    </div>
                                    {item.type === "video"? <video playsInline ref={video} style={{width: "100%", height: "100%"}} />: null}
                                </div>
                            ))}
                        </div>
                    }
                </div>
        </div>
    )
}