import React, { useEffect, useRef } from 'react';
import s from './addModuleBlock.module.scss';
import { AddModuleItem } from '../addModuleItem/addModuleItem';

export const AddModuleBlock = ({ post, new_post, setModal, title, inner = false, changeValuesData, setFiles, files })=>{
   
    const content = useRef()
    const item = useRef()
    
    useEffect(()=>{
        if(inner) item.current.style.cssText= `width: 99%; margin: 0px auto 15px; padding-top: 0px; padding-bottom: 0px;`
        window.scrollTo(0,0)
    },[]);
   
    const module_items_view = post?.map((el, index) => {
        return <AddModuleItem key={index} item={el} setModal={setModal} changeValuesData={changeValuesData} setFiles={setFiles} files={files}/>
    })
    

    return(
        <div className={s.item} ref={item}>
          
            {inner? <header><span>{title}</span></header> : null}
            
            <div className={s.content} ref={content}>

                {
                post? post?.values? 
                module_items_view :
                <AddModuleItem key={post.id} item={post} setModal={setModal} changeValuesData={changeValuesData} setFiles={setFiles} files={files}/> : null
                }
            </div>
        </div>
    )
}