import React, { useRef, useState } from 'react';
import { useUpdatePostMutation } from '../../../redux/apis/modulesApi';
import { Toast } from '../../../utils/hocs/toast';
import Swal from 'sweetalert2';

export const TextModal = ({close, values, type, langs, post_id})=>{
  
    const error_text = useRef();
    const [inputsValues, setInputsValues] = useState(values);
    const [linkValue, setLinkValue] = useState('123');

    const [updatePost] = useUpdatePostMutation();
    
    function changeInputValue(el){
       
        const current_lang = el.getAttribute('lang')
        const current_value = el.value;
        setInputsValues(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            const updateState = newState.map(el => {
                if(el.lang_id === parseInt(current_lang)){
                    el.value = current_value
                    return el
                } else return el
            })
            return updateState
        })
    }

    function changeLinkValue(value){
        setLinkValue(value)
    }

    async function sendData(){
        const data = {
            post_id: post_id,
            value: inputsValues,
            type: type
        }

        const response = await updatePost(data)
        if(response.data){
            Toast.fire({icon: 'success', title: 'Успіх'});
            close(false)
        }
        else if(response.error?.data?.message === 1000){
            Swal.fire({
                title: 'На жаль, у вас немає необхідних прав',
                confirmButtonText: 'Закрити',
                customClass: {
                  actions: 'modal',
                  cancelButton: 'order-1 right-gap',
                  confirmButton: 'order-2',
                  denyButton: 'order-3',
                }
              })
              return;
        }
        else Toast.fire({icon: 'error', title: 'Помилка'});
    }
    
    const textAreas_view = JSON.parse(JSON.stringify(langs))?.sort((a, b) => a.index > b.index)?.map(lang =>{
        const el = values.find(value => value.lang_id === lang.id)
        if(el){
            return(
                <div key={el.id} className="form_row">
                    <label>{`${type} - `}<span>{`${lang.key}`}</span></label>
                    <textarea value={inputsValues.find(el => el.lang_id === lang.id)?.value} rows="10" onChange={ev => changeInputValue(ev.target)} lang={lang.id}></textarea>
                </div>
            )
        }
    })
 
    return(
        <div className="modal_wrapper">
            <div className="modal">
                <h2>{values? "Редагувати блок" : "Додати блок"}</h2>
                <form className="modal_form" >
                    <div className="form_row">
                        <label>Тип</label>
                        <select value={type} disabled={values?.type || type? true:false}  type="select" >
                            <option value="text">Text</option>
                            <option value="link">Link</option>
                            <option value="image">Image</option>
                            <option value="video">Video</option>
                        </select>
                    </div>

                    {textAreas_view}
                   
                    {type === 'link'? <div className="form_row"><input type="text" name="alt" value={linkValue} onChange={ev => changeLinkValue(ev.target.value)}/></div> : null} 
                    <span className="error_text" ref={error_text}></span> 
                    <input className="button" type="button" value="Відправити" onClick={(ev)=>sendData()} />
                    <input className="button" type="submit" value="Скасувати" onClick={()=>close(false)}/>
                </form>
            </div>
        </div>
    )
}