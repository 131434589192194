import React, { useState } from 'react';
import { useAddTermsMutation, useDeleteTermsMutation, useGetTermsQuery, useUpdateTermsMutation } from '../../../redux/apis/termsApi';
import { getDate } from '../../../utils/hocs/getDate';
import * as yup from 'yup';
import s from './terms.module.scss';
import { Field, Form, Formik } from 'formik';
import { useGetLangsQuery } from '../../../redux/apis/langApi';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit_icon.svg';
import { EditModal } from '../../../components/editModal/editModal';
import { Preloader } from '../../../components/preloader/preloader';

export const Terms = ()=>{
    
    const [initialValues, setValues] = useState({"id": '', "value": '', "pseudo": '', "group": '', "lang_id": ''});
    const [modal, setModal] = useState(false);
    const [modalState, setModalState] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [langState, setLangState] = useState(false);
    const [validationValues, setSchema] = useState({
        value: yup.string().required('Обязательное поле').min(2, 'Нимимум 2 символа'),
        pseudo: yup.string().required('Обязательное поле').min(2, 'Нимимум 2 символа'),
        group: yup.string().required('Обязательное поле').min(2, 'Нимимум 2 символа'),
        lang_id: yup.number('Введите id языка (только цифры)').required('Обязательное поле'),
    });
    const [filters, setFilters] = useState({author: 0, pseudo: 0, group: 0, new: 0});

    let terms_view;
    let modal_errors = false;

    const lang_query = useGetLangsQuery({});
    const terms_query = useGetTermsQuery({});

    const [updateTerms] = useUpdateTermsMutation();
    const [addTerms] = useAddTermsMutation(); 
    const [deleteTerms] = useDeleteTermsMutation();
    
    if(terms_query.isError || lang_query.isError) console.log(terms.isError, );
    if(terms_query.isFetching || lang_query.isError) return <Preloader />
    
    if(!terms_query.data || !lang_query.data) return;

    if(!langState){
        setLangState(lang_query.data.data[0].id);
        return;
    }

    const uniqueUsers = terms_query.data.data.filter((obj, index, self) => {
        return self.findIndex((otherObj) => obj.author === otherObj.author) === index;
    });

    const uniquePseudo = terms_query.data.data.filter((obj, index, self) => {
        return self.findIndex((otherObj) => obj.pseudo === otherObj.pseudo) === index;
    });
    const uniqueGroup = terms_query.data.data.filter((obj, index, self) => {
        return self.findIndex((otherObj) => obj.group === otherObj.group) === index;
    });
    
    const lang = lang_query.data.data;
    let terms;
    if(!filters.new) terms = terms_query.data.data.filter(tm => tm.lang_id === langState);
    else terms = terms_query.data.data;
    if(filters.author) terms = terms.filter(tm => tm.author === filters.author);
    if(filters.pseudo) terms = terms.filter(tm => tm.pseudo === filters.pseudo);
    if(filters.group) terms = terms.filter(tm => tm.group === filters.group);
    if(filters.new) terms = terms.filter(tm => tm.value.length === 0);
    
    terms_view = terms.map(el => {
        const lang_name = lang.find(lang => lang.id === el.lang_id);
        return(
            <tr key={el.id} className={`anchor-${el.id}`}>
                <td><span className={s.terms_id}>{el.id}</span></td>
                <td><span className={s.value}><EditIcon onClick={()=>setModalState({type: "text", id: el.id, pseudo: el.pseudo})}/>{el.value}</span></td>
                <td><span>{el.pseudo}</span></td>
                <td><span>{el.group}</span></td>
                <td><span className={s.date}>{getDate(el.created_date)}</span></td>
                <td><span>{el.author}</span></td>
                <td><span className={s.lang}>{lang_name.key}</span></td>
            </tr>
        )
    });
    

    function toggleModal({ev, el}){
        setValues({id: '', value: '', pseudo: '', group: '', lang_id: ''});
        setSchema(prev => {prev.value = yup.string().required('Обязательное поле').min(2, 'Нимимум 2 символа'); return prev});
        setSchema(prev => {prev.lang_id = yup.number('Введите id языка (только цифры)').required('Обязательное поле'); return prev});
        if(ev) ev.preventDefault();
        if(el) setValues({id: el.id, value: el.value, pseudo: el.pseudo, group: el.group, lang_id: el.lang_id});
        lang.data.data.forEach(el=>{
            setSchema(prev => {delete prev[`value_${el.key}`]; return prev});
        });
        setModal(!modal);
    }

    function setModalValues(){
        setValues({id: '', value: '', pseudo: '', group: '', lang_id: ''})
        lang.forEach(el=>{
            setValues(prev => {prev[`value_${el.key}`] = ""; return prev});
            setSchema(prev => {prev[`value_${el.key}`] = yup.string().required('Обязательное поле').min(2, 'Нимимум 2 символа'); return prev});
        });
        setSchema(prev => {delete prev.value; delete prev.lang_id; return prev})
        setAddModal(true);
    }

    async function delTerms(id){
        const confirm = window.confirm(`Удалить термин с id - ${id}?`);
        if(!confirm) return;
        const response = await deleteTerms(id);
        if(response.error?.data.message === 18) alert('Не возможно удалить термин. Возможно он используеться в одном из блоков или не существует.')
    }

    function changeFilter(el){
        let value = el.options[el.selectedIndex].value;
        let name = el.options[el.selectedIndex].getAttribute('name');
        if(value === "0") value = 0;
        setFilters(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            newState[`${name}`] = name === 'author'? parseInt(value) : value;
            return newState;
        })
    }
    
    const validationSchema = yup.object().shape(validationValues);

    return(
        <div className={s.terms}>
            <div className={s.filters_wrapper}>
                <div className={s.filters}>
                    <div className={s.filter_row}>
                        <label>Автор</label>
                        <select name="author" onChange={(ev)=>changeFilter(ev.target)}>
                            <option value="0" name="author" selected>Усі</option>
                            {uniqueUsers.map(el => <option key={el.id} id={el.author} value={el.author} name="author">{el.author}</option>)}
                        </select>
                    </div>
                    <div className={s.filter_row}>
                        <label>Псевдонім</label>
                        <select name="pseudo" onChange={(ev)=>changeFilter(ev.target)}>
                            <option value="0" name="pseudo" selected>Усі</option>
                            {uniquePseudo.map(el => <option key={el.id} id={el.pseudo} value={el.pseudo} name="pseudo">{el.pseudo}</option>)}
                        </select>
                    </div>
                    <div className={s.filter_row}>
                        <label>Група</label>
                        <select name="group" onChange={(ev)=>changeFilter(ev.target)}>
                            <option value="0" name="group" selected>Усі</option>
                            {uniqueGroup.map(el => <option key={el.id} id={el.group} value={el.group} name="group">{el.group}</option>)}
                        </select>
                    </div>
                    <div className={s.filter_row}>
                        <label>Порожні</label>
                        <select name="new" onChange={(ev)=>changeFilter(ev.target)}>
                            <option value="0" name="new" selected>Усі</option>
                            <option value="1" name="new">Порожні</option>
                        </select>
                    </div>
                </div>
                <div className={s.lang_block}>
                {
                lang.map(el => {
                    return (
                        <div key={el.id} className={s.lang_item} onClick={()=>setLangState(el.id)}>
                            <div className={s.lang}>{el.key}</div>
                        </div>
                    )
                })
                }
                </div>
            </div>
            <div className={s.content}>
                <table>
                    <thead>
                        <tr>
                            <td><span>id</span></td>
                            <td><span>Значення</span></td>
                            <td><span>Псевдонім</span></td>
                            <td><span>Група</span></td>
                            <td><span className={s.date}>Дата створення</span></td>
                            <td><span>Автор</span></td>
                            <td><span>Мова</span></td>
                            {/* <td className={s.t_tools}>
                                <span className={s.add_element} onClick={setModalValues}></span>
                            </td> */}
                        </tr>
                    </thead>
                    <tbody>
                    {terms_view}
                    </tbody>
                </table>
            </div>
            {
            modal || addModal? (
                <div className="modal_wrapper">
                    <div className="modal">
                        <h2>{modal? "Редактирование термина" : "Добавить термин"}</h2>
                    <Formik
                        validationSchema={validationSchema}
                        initialValues={initialValues}
                        onSubmit={(values)=> {
                            if(modal) {
                                updateTerms(values);
                                setModal(false);
                            }
                            else {
                                lang.data.data.forEach(el => {
                                    const value = values[`value_${el.key}`];
                                    const pseudo = values.pseudo;
                                    const group = values.group;
                                    const lang_id = el.id;
                                    const result = {value: value, pseudo: pseudo, group: group, lang_id: lang_id}
                                    addTerms(result);
                                })
                                setAddModal(false);
                            } 
                        }}
                        >
                        {({errors, touched}) => (
                            <Form className="modal_form">
                            {modal?
                                (<div className="form_row">
                                    <label>
                                        Значение
                                    </label>
                                    <Field name="value" type="text"/>
                                    <span className="error_text">{touched.value? errors.value : null}</span>
                                </div>):
                            (
                                lang.data.data.map(el => {
                                    return(
                                        <div key={el.id} className="form_row">
                                            <label>
                                                {`Значение ${el.key}`}
                                            </label>
                                            <Field name={`value_${el.key}`} type="text"/>
                                            <span className="error_text">{touched[`value_${el.key}`]? errors[`value_${el.key}`] : null}</span>
                                        </div>
                                    )
                                })
                            )}

                            <div className="form_row">
                                <label>
                                    Псевдоним
                                </label>
                                <Field name="pseudo" type="text"/>
                                <span className="error_text">{touched.pseudo? errors.pseudo : null}</span>
                            </div>
                            
                            <div className="form_row">
                                <label>
                                    Группа
                                </label>
                                <Field name="group" type="text"/>
                                <span className="error_text">{touched.group? errors.group : null}</span>
                            </div>

                            {modal? <div className="form_row">
                                <label>
                                    Язык
                                </label>
                                <Field component="select" name="lang_id">
                                    {lang.data.data.map(el => {
                                        return <option key={el.id} value={el.id}>{el.key}</option>
                                    })}
                                </Field>
                                <span className="error_text">{touched.lang_id? errors.lang_id : null}</span>
                            </div> : null}

                            <button type="submit">Отправить</button>
                            <button type="submit" onClick={(ev)=>modal? toggleModal({ev}) : setAddModal(false)}>Отмена</button>
                            <span className="error_text">{modal_errors}</span>
                        </Form>
                        )}
                    </Formik>
                </div>
            </div>
            ): null
            }
            {modalState? <EditModal type={modalState.type}  id={modalState.id} pseudo={modalState.pseudo} close={setModalState} page="terms"/> : null}
        </div>
    )
}