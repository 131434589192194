import React, { useEffect, useState } from 'react';
import s from './module.module.scss';
import { useGetModuleForCRMQuery, useModuleUploadMutation, useUpdatePostMutation } from '../../../redux/apis/modulesApi';
import { Preloader } from '../../../components/preloader/preloader';
import { ModuleBlock } from '../../../components/modules/moduleBlock/moduleBlock';
import { TextModal } from '../../../components/modules/textModal/textModal';
import { MediaModal } from '../../../components/modules/mediaModal/mediaModal';
import { AddModuleBlock } from '../../../components/modules/addModuleBlock/addModuleBlock';
import { useSelector } from 'react-redux';
import { getSequenceTypes } from '../../../utils/getSequenceTypes';
import { PreparationModuleData } from '../../../utils/PreparationModuleData';
import { Toast } from '../../../utils/hocs/toast';
import { Arrows } from '../../../components/arrows/arrows';
import Swal from 'sweetalert2';

export const Module = ({ id, title })=>{

    const [viewMode, setViewMode] = useState(true) 
    const [textModal, setTextModal] = useState({state: false, values: false, langs: false, type: false, post_id: false});
    const [mediaModal, setMediaModal] = useState({state: false, values: false, type: false, post_id: false});
    const [newModuleData, setNewModuleData] = useState(false);
    const [files, setFiles] = useState([]);
    const [uploadedModule, setUploadedModule] = useState(false);
    const [currentModule, setCurrentModule] = useState(false);
    const [langState, setLangState] = useState(false);

    const langs = useSelector(store => store.main.langs)

    const [uploadModule, {isLoading: isUploadModuleLoading}] = useModuleUploadMutation();
    const [updateMedia, {isLoading: isUploadMediaLoading}] = useUpdatePostMutation()
    const { data, isLoading, isFetching } = useGetModuleForCRMQuery(id);

    useEffect(()=>{
        goToModule(uploadedModule)
    },[data]);

    useEffect(()=>{
        setLangState(langs[0]?.id)
    },[langs]);

    if( isLoading || isFetching || isUploadModuleLoading || isUploadMediaLoading) return <Preloader />
    // console.log(data);
    function setSequence(){
        const sequence = getSequenceTypes(id, data.types, title, langs, data.post.id)
        console.log(sequence);
        setNewModuleData(sequence)
    }

    // FUNCTIONS

    function openModal({state, langs, values, type, post_id}){
        if(type === "text" || type === "link") setTextModal({state: state, langs: langs, values: values, type: type, post_id: post_id})
        if(type === "image" || type === "video") setMediaModal({state: state, values: values, type: type, post_id: post_id})
    }

    
    async function sendNewModule(){
        const newData = JSON.parse(JSON.stringify(newModuleData))
        const data = PreparationModuleData(newData, ['title', 'editId', 'type', 'sequence', 'id', 'isBlock', 'id_allowed'])
        const response = await uploadModule(data)
        
        if(response.data?.message === 28){
            if(files.length){
                const uploadResult = await Promise.all(files.map(async file => {
                    const post = response.data.data.find(el => el.alt === file.editID);
                    if(!post) return
                    const post_id = post.id
                    
                    const uploadResult = await Promise.all(Object.entries(file.file)?.map(async el => {
                        
                        const formData = new FormData()
                        formData.append('file', el[1].file)
                        formData.append('alt', "test")
                        formData.append("post_id", post_id)
                        formData.append("type", "image")
                        formData.append("with_file", true)
                        formData.append("langkey", el[0])
                        formData.append("pseudo", file.editID)
                        formData.append("default", el[1].default)

                        const response_media = await updateMedia(formData)
                        return response_media.data?.message
                    }))
                    if(uploadResult.includes(28)) return 28
                    else return false
                }))
                console.log("uploadResult", uploadResult);
                if(uploadResult.includes(28)){
                    Toast.fire({icon: 'success', title: 'Успіх'});
                    setViewMode(true)
                    setNewModuleData(false)
                    setFiles([])
                }
                else Toast.fire({icon: 'error', title: 'Помилка'});
                setNewModuleData(false)
                setNewModuleData(false)
                setFiles([])
                setUploadedModule(response.data?.post_id)
            }
            else{
                Toast.fire({icon: 'success', title: 'Успіх'});
                setViewMode(true)
                setNewModuleData(false)
                setFiles([])
                setUploadedModule(response.data?.post_id)
            }
        }
        else if (response.error?.data?.message === 1000){
            Swal.fire({
                title: 'На жаль, у вас немає необхідних прав',
                confirmButtonText: 'Закрити',
                customClass: {
                  actions: 'modal',
                  cancelButton: 'order-1 right-gap',
                  confirmButton: 'order-2',
                  denyButton: 'order-3',
                }
            })
            return;
        }
    }

    function goToModule(id){
        const anchor = document.getElementsByClassName(`anchor-${id}`)
        window.scrollTo({
            top: anchor[0]?.offsetTop,
            left: 100,
            behavior: "smooth",
        });
        setUploadedModule(false)
    }

    // VIEWS

    const module_blocks_view = data?.value?.map((el, index) => {
        return <ModuleBlock key={el.id} post={el} types={data.types} setModal={openModal} setViewMode={setViewMode} setNewModuleData={setNewModuleData} parrent_id={data.post.id} index={index} langState={langState} setLangState={setLangState}/>
    })

    const module_buttons_view = data?.value.map((el, index) => {
        let name = el.data?.find(el => el.title === 'name')
        if(name) name = name.value?.[0]?.value
        else name = `${el.title} ${index + 1}`
        return(
            <div key={index} className={`${s.page_item} ${currentModule === el.id? s.active:''}`} onClick={()=> goToModule(el.id)}>
                <p>{el.marker? el.marker : name}</p>
            </div>
        )
    })
   
    return(
        <>
        {viewMode? 
        (
            <div className={s.module}>
               <header className={s.header}>{module_buttons_view}</header>
                <div className={s.content}>
                    {module_blocks_view}
                    {data?.value?.length? null : <div className="button" onClick={()=>{setViewMode(false); setSequence()}}>{`Додати блок`}</div>}
                </div>
                {textModal.state? <TextModal close={setTextModal} values={textModal.values} langs={textModal.langs} type={textModal.type} post_id={textModal.post_id}/> : null}
                {mediaModal.state? <MediaModal close={setMediaModal} values={mediaModal.values} type={mediaModal.type} post_id={mediaModal.post_id}/> : null}
            </div>
        )
        :
        (
            <div className={s.module}>
                <h1>{title}</h1>
                <div className={s.content}>

                    <AddModuleBlock post={newModuleData.values} title={newModuleData.title} setModal={openModal} changeValuesData={setNewModuleData} setFiles={setFiles} files={files}/>

                    <div className={s.buttons}>
                        <div className="button" onClick={sendNewModule}>{`Зберегти`}</div>
                        <div className="button" onClick={()=>setViewMode(true)}>{`Закрити`}</div>
                    </div>
                </div>
                {textModal.state? <TextModal close={setTextModal} values={textModal.values} langs={textModal.langs} type={textModal.type} post_id={textModal.post_id}/> : null}
                {mediaModal.state? <MediaModal close={setMediaModal} values={mediaModal.values} type={mediaModal.type} post_id={mediaModal.post_id}/> : null}
            </div>
        )
        }
        <Arrows/>
        </>
    )
}