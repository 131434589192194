import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const api = process.env.REACT_APP_API_ADDRESS;

export const modulesApi = createApi({
    reducerPath: 'modulesApi',
    tagTypes: ['Modules'],
    baseQuery: fetchBaseQuery({
      baseUrl: api,
      prepareHeaders: (headers) => {
        const token = localStorage.getItem("token");
        if(token) headers.set("token", token);
        return headers;
      }
    }),
    endpoints: (builder) => ({

      getModules: builder.query({
        query: (module) =>  `/api/modules/get`,
        providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Modules', id })),
              { type: 'Modules', id: 'LIST' },
            ]
          : [{ type: 'Modules', id: 'LIST' }],
      }),

      getModule: builder.query({
        query: (module) =>  `/api/module/get?id=${module}`,
        providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Modules', id })),
              { type: 'Modules', id: 'LIST' },
            ]
          : [{ type: 'Modules', id: 'LIST' }],
      }),

      getModuleForCRM: builder.query({
        query: (module) =>  `/api/module/crm/get?id=${module}`,
        providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Modules', id })),
              { type: 'Modules', id: 'LIST' },
            ]
          : [{ type: 'Modules', id: 'LIST' }],
      }),

      updatePost: builder.mutation({
        query: (data) => ({
          url: '/api/module/update',
          method: 'PUT',
          body: data
        }),
        invalidatesTags: [{ type: 'Modules', id: 'LIST' }],
      }),

      deletePost: builder.mutation({
        query: (data) => ({
          url: '/api/module/delete',
          method: 'DELETE',
          body: {id: data}
        }),
        invalidatesTags: [{ type: 'Modules', id: 'LIST' }],
      }),

      moduleUpload: builder.mutation({
        query: (data) => ({
          url: '/api/module/upload',
          method: 'POST',
          body: {data}
        }),
        invalidatesTags: [{ type: 'Modules', id: 'LIST' }],
      }),

      updatePageSettings: builder.mutation({
        query: (data) => ({
          url: '/api/module/settings/update',
          method: 'POST',
          body: {data}
        }),
        invalidatesTags: [{ type: 'Modules', id: 'LIST' }],
      }),

      updateModuleMarker: builder.mutation({
        query: (data) => ({
          url: '/api/module/marker/update',
          method: 'POST',
          body: {data}
        })
      }),

      updateDefaultImage: builder.mutation({
        query: (data) => ({
          url: '/api/module/update/defaultimage',
          method: 'POST',
          body: {data}
        }),
        invalidatesTags: [{ type: 'Modules', id: 'LIST' }],
      }),
      
    }),
})

export const {
  useGetModulesQuery,
  useGetModuleQuery,
  useGetModuleForCRMQuery,
  useUpdatePostMutation,
  useDeletePostMutation,
  useModuleUploadMutation,
  useUpdatePageSettingsMutation,
  useUpdateModuleMarkerMutation,
  useUpdateDefaultImageMutation
} = modulesApi;