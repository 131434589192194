import React from 'react';
import s from './moduleItem.module.scss';
import { ModuleFileItem } from '../moduleFileItem/moduleFileItem';
import { ModuleTextItem } from '../moduleTextItem/moduleTextItem';
import { ModuleBlock } from '../moduleBlock/moduleBlock';

export const ModuleItem = ({ item, setModal, types, setViewMode, setNewModuleData, parrent_id, parrent_type, index, langState, setLangState })=>{

    if(item.sequence?.length){
        return <ModuleBlock post={item} setModal={setModal} inner={true} types={types} setViewMode={setViewMode} setNewModuleData={setNewModuleData} parrent_id={parrent_id} parrent_type={parrent_type} index={index} langState={langState} setLangState={setLangState}/>
    }
    else{
        return(
            <div className={`${s.item} anchor-${item?.id}`}>
                <div className={s.content}>
                
                {item.type === "image" || item.type === "video"?
                    <ModuleFileItem data={item} setModal={setModal}/> : <ModuleTextItem data={item} setModal={setModal} langState={langState} setLangState={setLangState}/>
                }
                </div>
            </div>
        )
    }
}